import styled from "styled-components"

export const LayoutWrapper = styled.section`
  /* display: flex; */
`

export const LayoutMain = styled.main`
  width: 100%;
`

export const LayoutMainContent = styled.div`
`




