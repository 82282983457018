import styled from "styled-components"
import media from "styled-media-query";
import { Link } from "gatsby"
import { Whatsapp } from "@styled-icons/fa-brands/Whatsapp"
import { Instagram } from "@styled-icons/fa-brands/Instagram"


export const HeaderWrapper = styled.nav`
display: flex;
justify-content: space-between;
padding-top: 32px;
padding-bottom: 40px;

${media.lessThan("small")`
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
`}


`

export const HeaderLinks = styled.div`

`

export const HeaderLinksList = styled.ul`
display: flex;
margin-top: 34px;
text-transform: uppercase;
font-family: 'Montserrat';

${media.lessThan("small")`
justify-content: space-around;
flex-wrap: wrap;
margin-left: 0;
margin-top: 25px;
text-align: center;
`}

`

export const HeaderLinksItem = styled.li`
margin-left: 32px;

    ${media.lessThan("small")`
        margin-left: 0;

        &:last-child {
            position: absolute;
            top: 23px;
            right: 15px;
        }

    `}
    

`

export const HeaderLinksLink = styled(Link)`
font-size: 14px;
font-weight: 600;
color: #000;
text-decoration: none;
position: relative;

&:before {
    content: '';
    position: absolute;
    top: -8px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #000;
    transition: width 0.16s ease-out;
}

&:hover:before {
    width: 20%;
}

&.active:before {
    width: 20%;
}

`

export const IconWrapper = styled.a`
text-decoration: none;
border-radius: 30px;
display: inline-block;
padding: 3px;
margin-top: -8px;
color: #FFF;
background: #000;
border: solid 2px #000;
transition: 0.12s ease-out;


&:hover {
    border-radius: 8px;
}

&:first-child {
    margin-right: 10px;
}

`

export const IconWhatsapp = styled(Whatsapp)`
width: 22px;
height: 22px;
`

export const IconInstagram = styled(Instagram)`
width: 22px;
height: 22px;
`