import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

const Logo = () => {
    const { logoImage } = useStaticQuery(
        graphql`
            query {
                logoImage: file(relativePath: { eq: "mikio-fotos.png" }) {
                    childImageSharp {
                        fixed(quality: 100, width: 70, height: 80) {
                            ...GatsbyImageSharpFixed_tracedSVG
                        }
                    }
                }
            }`
    )

    return (
        <Link to="/">
            <Img fixed={logoImage.childImageSharp.fixed} alt="Fotografo Empresarial | Mikio Fotos" />
        </Link>
    )
}

export default Logo