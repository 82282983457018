import React from 'react'

import * as S from "./styled"

const Footer = () => {
    const pageName = typeof window !== 'undefined' ? window.location.href.split('/').pop() : ''

    return (
        <S.FooterWrapper style={{ display: pageName === 'contato' ? 'none' : 'block' }}>
            <S.FooterContent className="wrapper">
                <S.SectionContact>
                    <S.SectionContactTitle>Entre em contato e solicite um orçamento</S.SectionContactTitle>
                    <S.ButtonContact href="https://wa.me/5511994077776" target="_blank" title="Whatsapp" rel="noopener noreferrer">Contato</S.ButtonContact>
                </S.SectionContact>
                <S.SectionDetails>
                    <S.Email href="mailto:ericksahara@gmail.com" target="_blank" title="E-mail" rel="noopener noreferrer">ericksahara@gmail.com</S.Email>
                    <S.Social>{new Date().getFullYear()} Mikio Fotos | São Paulo</S.Social>
                    <S.Tel href="https://wa.me/5511994077776" target="_blank" title="Whatsapp" rel="noopener noreferrer">(11) 99407-7776</S.Tel>
                </S.SectionDetails>
            </S.FooterContent>
        </S.FooterWrapper>
    )

}


export default Footer