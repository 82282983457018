import React from 'react'
import Logo from '../Logo'
import * as S from "./styled"

const Header = () => (
    <S.HeaderWrapper className="wrapper">
        <Logo />
        <S.HeaderLinks>
            <S.HeaderLinksList>
                <S.HeaderLinksItem>
                    <S.HeaderLinksLink to="/" activeClassName="active">Empresarial</S.HeaderLinksLink>
                </S.HeaderLinksItem>
                <S.HeaderLinksItem>
                    <S.HeaderLinksLink to="/retrato" activeClassName="active">Retrato</S.HeaderLinksLink>
                </S.HeaderLinksItem>
                <S.HeaderLinksItem>
                    <S.HeaderLinksLink to="/contato" activeClassName="active">Contato</S.HeaderLinksLink>
                </S.HeaderLinksItem>
                <S.HeaderLinksItem>
                    <S.IconWrapper href="https://wa.me/5511994077776" title="Whatsapp" target="_blank" rel="noopener noreferrer">
                        <S.IconWhatsapp />
                    </S.IconWrapper>
                    <S.IconWrapper href="https://www.instagram.com/mikiofotos/" title="Instagram" target="_blank" rel="noopener noreferrer">
                        <S.IconInstagram />
                    </S.IconWrapper>
                </S.HeaderLinksItem>
            </S.HeaderLinksList>
        </S.HeaderLinks>
    </S.HeaderWrapper>
)

export default Header