import styled from "styled-components"
import media from "styled-media-query";
import { Link } from "gatsby"

export const FooterWrapper = styled.footer`
display: flex;
justify-content: space-between;
background-color: #000;
margin: 15px;
`
export const FooterContent = styled.div`
padding-top: 65px;
padding-bottom: 35px;

${media.lessThan("small")`
padding-top: 35px;
`}


`

export const SectionContact = styled.div`
text-align: center;
margin-bottom: 60px;

${media.lessThan("small")`
    width: auto;
    margin-bottom: 26px;
`}


`

export const SectionContactTitle = styled.h2`
    font-family: 'Montserrat';
    letter-spacing: 0.5px;
    font-weight: 600;
    font-size: 14px;
    color: #FFF;
    text-transform: uppercase;
    margin-bottom: 20px;

${media.lessThan("small")`
    line-height: 1.6;
`}

`


export const ButtonContact = styled.a`
    font-family: 'Montserrat';
    letter-spacing: 0.5px;
    font-weight: 600;
    display: inline-block;
    font-size: 14px;
    color: #FFF;
    text-transform: uppercase;
    padding: 15px 35px;
    text-decoration: none;
    border: solid 2px #FFF;
    border-radius: 40px;
    transition: all 0.2s ease-in;

    &:hover {
        background: #FFF;
        color: #000;
    }


`

export const SectionDetails = styled.div`
    display: flex;
    justify-content: space-between;

${media.lessThan("small")`
    display: block;
    text-align: center;
`}

`

export const Email = styled.a`
    width: 33.33%;
    text-align: left;
    font-family: 'Lato';
    font-size: 14px;
    color: #FFF;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`

export const Social = styled.div`
    width: 33.33%;
    text-align: center;
    font-family: 'Montserrat';
    letter-spacing: 0.5px;
    font-weight: 600;
    font-size: 10px;
    color: #FFF;
    text-transform: uppercase;
    border-radius: 40px;

${media.lessThan("small")`
    width: auto;
    margin: 15px 0 10px 0;
`}

`

export const Tel = styled.a`
    width: 33.33%;
    text-align: right;
    font-family: 'Lato';
    font-size: 14px;
    color: #FFF;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`