import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
	-webkit-clip-path: inset(50%) !important;
		clip-path: inset(50%) !important;  /* 2 */
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;            /* 3 */
}

  * {
    box-sizing: border-box;
  }
  body {
    background: #FFF;
    line-height: 1;
    font-size: 100%;
    font-family: "Lato", sans-serif;
  }
  img {
    display: block;
  	width: 100%;
  	height: auto;
  }

  .wrapper {
    width: 100%;
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

.item-66 {
  width: 66.66%;
}

.item-50 {
  width: 50%;
}

.item-33 {
  width: 33.33%;
  position: relative;
  overflow: hidden;
}

.item-33 .gallery-item {
  position: absolute !important;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translate3d(0, -50%, 0);
}

.gallery-row-portrait > .item-33 {

}

.gallery-row-portrait > .item-33 .gallery-item {
  position: static !important;
  transform: none;
}

/* Dialog component*/
[data-reach-dialog-overlay] {
  background-color: #FFF;
}

.close-button {
  cursor: pointer;
  background: none;
  border: none;
  position: absolute;
  font-size: 38px;
  right: 25px;
  top: 25px;
}

.close-button:focus {
  outline: none;
  border: none;
}

.order-1 {
  order: 1;
}

@media (max-width: 450px) {

.wrapper {
  padding-left: 15px;
  padding-right: 15px;
}

.item-66 {
  width: 100%;
}

.item-50 {
  width: 100%;
}

.item-33 {
  width: 100%;
  position: relative;
  overflow: visible;
}

.item-33 .gallery-item {
  position: relative !important;
  width: 100%;
  left: auto;
  top: auto;
  transform: none;
}

[data-reach-dialog-content] {
  width: auto;
}

}



`
export default GlobalStyles